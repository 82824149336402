<template>
	<v-card class="ma-2 pa-2 elevation-1">
		<h2 class="text-titulo">VPM feed database</h2>
		<div id="products">
			<v-container>
				<v-row>
					<v-col md="auto">
						<v-text-field
							label= "Filter"
							v-model="buscador"
							v-on:keyup.enter="fnLoad"
							clearable
							style="max-width: 250px;"></v-text-field>
					</v-col>
					<v-col md="auto">
						<v-select
							:items="shopping"
							label="Shop"
							v-model="shop"
							@change="csvdata = []"></v-select>
					</v-col>
					<v-col md="auto">
						<v-btn
							color="pink lighten-2"
							dark
							large
							@click = "fnMakecsv"
							:loading="stBtnLoad">Generate shopify csv<v-icon right>mdi-rss</v-icon></v-btn>
					</v-col>
					<v-col md="auto" v-if="csvdata.length">
						<json-csv 
							:data="csvdata" 
							class="v-btn v-btn--contained v-size--large theme--dark blue darken-4">
								Download<v-icon dark right>mdi-arrow-down-bold-hexagon-outline</v-icon>
						</json-csv>
					</v-col>
				</v-row>
			</v-container>
			<v-data-table
				:headers="cabecera"
				:items="listado"
				:options.sync="options"
				:server-items-length="totales"
				:loading="stLoading"
				:footer-props="{ 
					showFirstLastPage: true,
					itemsPerPageOptions: [25, 50, 100, 250]
				}"
				class="elevation-1">
					<template v-slot:item.video="{ item }">
						<v-icon v-if="item.video">mdi-video</v-icon>
						<v-icon v-else>mdi-video-off</v-icon>
					</template>
				</v-data-table>
		</div>
	</v-card>
</template>

<script>
import axios from "axios"
import JsonCsv from 'vue-json-csv'

export default {
	name: 'vpmfeed',
	metaInfo: {
		title: 'VPM Feed'
	},
	components: {
		JsonCsv
	},
	data() {
		return {
			cabecera: [
				{ text: 'ID', value: 'id' },
				{ text: 'SKU', value: 'sku' },
				{ text: 'Brand', value: 'brand' },
				{ text: 'Title', value: 'title' },
				{ text: 'Collection', value: 'collection' },
				{ text: 'Stock', value: 'stock' },
				{ text: 'Color', value: 'color' },
				{ text: 'Size', value: 'size' },
				{ text: 'Sale', value: 'sale' },
				{ text: 'Video', value: 'video' },
				{ text: 'Status', value: 'status' }
			],
			listado: [],
			shopping: [
				{ text: 'Steven Even', value: 'steven' },
				{ text: 'Mens Underwear Store', value: 'mus' },
				{ text: 'Miami Swims', value: 'miami' }
			],
			options: {},
			totales: 0,
			buscador: null,
			shop: 'steven',
			page: 0,
			stLoading: false,
			stBtnLoad: false,
			csvdata: []
		}
	},
	watch: {
    options: {
      handler () {
        this.fnLoad()
      },
      deep: true,
    },
  },
	mounted() {
		this.fnLoad()
		this.$store.state.titulofix = 'VPM Feed database'
	},
	methods: {
		fnLoad() {
			this.csvdata = []
			this.stLoading = true
			const { page, itemsPerPage } = this.options;
			axios.post('/vpmfeed', { word: this.buscador, page: page, limit: itemsPerPage })
				.then(response => {
					this.listado = response.data.items
					this.totales = response.data.total
					this.stLoading = false
				})
		},
		fnMakecsv() {
			this.stBtnLoad = true
			axios.post('/makeshpcsv', { word: this.buscador, shop: this.shop })
				.then(response => {
					this.csvdata = response.data
					this.stBtnLoad = false
				})
		}
	}
}	
</script>